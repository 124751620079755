import React from "react";

const Timebar = ({ checked }) => {
  return (
    <div className="w-full bg-gray-100 rounded-full h-2.5 dark:bg-gray-700">
      <div
        className={
          "bg-cyan-500 h-2.5 rounded-full" +
          (!checked ? " animate-count" : " w-0")
        }
      ></div>
    </div>
  );
};

export default Timebar;
