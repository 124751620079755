import React from "react";

const Model = ({ name }) => {
  return (
    <div className="rounded-lg bg-cyan-500">
      <div className="text-xs text-center text-white bg-cyan-600/30 rounded-t-lg">
        Model
      </div>
      <div className="pt-8 pb-10 text-center text-3xl text-white">{name}</div>
    </div>
  );
};

export default Model;
