import React from "react";

const NextButton = ({ checked, onNext }) => {
  return (
    <button
      onClick={onNext}
      className={
        "bg-cyan-500 py-2 text-gray-100 w-full rounded-lg" +
        (!checked ? " invisible" : "")
      }
    >
      weiter
    </button>
  );
};

export default NextButton;
