import React from "react";

const StartButton = ({ onNextRound }) => {
  return (
    <button
      onClick={onNextRound}
      className={"bg-cyan-500 py-2 text-gray-100 w-full rounded-lg"}
    >
      Spiel starten
    </button>
  );
};

export default StartButton;
