import React from "react";
import Answer from "./Answer";
import NextButton from "./NextButton";
import Model from "./Model";
import Timebar from "./Timebar";

import { useState } from "react";

const Round = ({ data, onNextRound, onRight, onWrong, playerId }) => {
  const [roundState, setRoundState] = useState({ checked: false });

  const checkAnswer = (index) => {
    fetch("https://api.carquiz.de/model-quiz-participation", {
      method: "POST",
      body: JSON.stringify({
        uuid: data.uuid,
        player_id: playerId.playerId,
        answer_manufactor_id: data.manufacture[index].id,
        model_id: data.model.id,
      }),
    });
    const newData = Object.assign({}, data);
    // Getätigte Antwort auswerten
    newData.manufacture[index].result =
      data.manufacture[index].id === data.model.manufacture_id;

    if (!newData.manufacture[index].result) {
      // Antwort falsch, richtige Anwort zusätzlich anzeigen
      data.manufacture.forEach((item, index) => {
        if (item.id === data.model.manufacture_id) {
          newData.manufacture[index].result = true;
        }
      });
    }
    //setData(newData);
    setRoundState({ checked: true });

    // Ergebnis Counter
    if (data.manufacture[index].id === data.model.manufacture_id) onRight();
    else onWrong();
  };
  const onNext = () => {
    setRoundState({ checked: false });
    onNextRound();
  };

  return (
    <div>
      <div className="divide-y divide-gray-300/50">
        <div className="space-y-6 py-8 text-base leading-7 text-gray-600">
          <Model name={data.model.name} />

          <Timebar checked={roundState.checked} />
          <div className="grid grid-cols-2 gap-5">
            {data.manufacture.map((manufacture, index) => (
              <Answer
                key={index}
                value={manufacture.name}
                result={manufacture.result}
                index={index}
                onCheck={checkAnswer}
              />
            ))}
          </div>
        </div>
        <NextButton checked={roundState.checked} onNext={onNext} />
      </div>
    </div>
  );
};

export default Round;
