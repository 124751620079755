import Quiz from "./Quiz";
import { v4 as uuidv4 } from "uuid";
import React, { useState, useEffect } from "react";

const Game = () => {
  const [playerId] = useState(() => {
    return localStorage.getItem("playerId") || uuidv4();
  });

  useEffect(() => {
    localStorage.setItem(
      "playerId",
      localStorage.getItem("playerId") || uuidv4()
    );
  });

  return (
    <div className="relative flex flex-col justify-center overflow-hidden bg-teal-50">
      <div className="relative bg-white pt-10 pb-8 shadow-md ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
        <div className="mx-auto max-w-md px-6">
          <img
            src="http://carquiz.de/carquiz-logo.svg"
            className="h-10"
            alt="CarQuiz"
          />
          <Quiz playerId={playerId} />
        </div>
      </div>
    </div>
  );
};

export default Game;
