import React, { useEffect, useState } from "react";
import Round from "./Round";
import StartButton from "./StartButton";
import { v4 as uuidv4 } from "uuid";

const Quiz = (playerId) => {
  const [round, setRound] = useState({
    uuid: "",
    model: { name: "", manufacture_id: 0 },
    manufacture: [],
  });
  const [quiz, setQuiz] = useState({ rightInRow: 0 });
  const [loading, setLoading] = useState(true);
  const [highscore, setHighscore] = useState(() => {
    return localStorage.getItem("highscore") || 0;
  });

  const nextRound = () => {
    loadRoundData();
  };

  const loadRoundData = () => {
    fetch("https://api.carquiz.de/car")
      .then((res) => res.json())
      .then((newRound) => {
        var newManufacture = [];

        newRound.manufacture.forEach((item) => {
          newManufacture.push({
            name: item.name,
            id: item.id,
            result: null,
          });
        });
        setRound({
          uuid: uuidv4(),
          model: {
            id: newRound.model.id,
            name: newRound.model.name,
            manufacture_id: newRound.model.car_manufacture_id,
          },
          manufacture: newManufacture,
        });
        setLoading(false);
      });
  };

  const onRight = () => {
    if (quiz.rightInRow + 1 > highscore) {
      setHighscore(quiz.rightInRow + 1);
    }
    // right in row  +1
    setQuiz({ rightInRow: quiz.rightInRow + 1 });
  };

  const onWrong = () => {
    // right in row = 0
    setQuiz({ rightInRow: 0 });
  };

  useEffect(() => {
    localStorage.setItem("highscore", highscore);
  });

  if (loading)
    return (
      <div className="py-12">
        <div className="pb-12">
          Im <strong>CarQuiz</strong> kannst du zeigen, wie gut du dich mit
          Automodellen und -Herstellern auskennst.
          <br />
          Wenn du das Spiel startest, dann kannst du zu dem angezeigten Modell
          den Hersteller auswählen!
        </div>
        <StartButton onNextRound={nextRound} />
      </div>
    );
  return (
    <div>
      <Round
        data={round}
        onNextRound={nextRound}
        onRight={onRight}
        onWrong={onWrong}
        playerId={playerId}
      />
      <div className="pt-2 text-xs text-center text-gray-400">
        Richtige Antworten: {quiz.rightInRow}
        <br />
        Dein Highscore: {highscore}
      </div>
    </div>
  );
};

export default Quiz;
