import React from "react";

const Answer = ({ value, onCheck, result, index }) => {
  return (
    <button
      className={
        result === null
          ? "h-20 rounded-lg bg-gray-100 shadow-md"
          : result
          ? "h-20 rounded-lg bg-green-300 shadow-md ring ring-green-100"
          : "h-20 rounded-lg bg-red-300 shadow-md ring ring-red-100"
      }
      onClick={() => {
        onCheck(index);
      }}
    >
      {value}
    </button>
  );
};

export default Answer;
